import React from 'react'
import PropTypes from 'prop-types'

import { OutboundLink } from 'gatsby-plugin-google-analytics'

function Education({ degree, school, date, text, thesis, thesisName, thesisLink }) {
  return (
    <li>
      <h3><strong>{degree},</strong> on {school}</h3>
      <p>{date}</p>
      <p>{text}</p>
      <p><OutboundLink href={thesisLink}>{thesis}</OutboundLink>: {thesisName}.</p>
    </li>
  )
}

Education.propTypes = {
  degree: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Education
