import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Experience from '../components/experience'
import Project from '../components/project'
import Education from '../components/education'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="About"
         keywords={[`about`, `povoda`, `developer`, `researcher`, `artificial intelligence`, `machine learning`]}/>

    <section id="welcome">
      <h1>Lukáš <span>Povoda</span></h1>
      <ul className="subheading">
        <li>Senior Developer / Artificial Intelligence Researcher</li>
        <li><i className="fa fa-map-marker"/> Brno, Czech Republic</li>
        <li><i className="fa fa-globe"/> povoda.eu</li>
        <li><Link to="/contact/"><i className="fa fa-envelope-o"/> Contact me</Link></li>
      </ul>
    </section>

    <section className="bg-secondary">
      <div className="row">
        <div className="content col-12 col-lg-9">
          <h2>Short Biography</h2>
          <p>
            Lukas Povoda was born in 1990.
            He received his Ph.D. from the Faculty of Electrical Engineering and Communication, Brno University of
            Technology, Czech Republic in 2018.
            His research interests include data-mining (especially text-mining), machine learning and optimization
            techniques in this field. His primary objective is to determine the meaning of texts with deep learning
            methods and deep text understanding methods.
            Currently, he is working as a Fullstack Senior Developer on multiple web applications.
            He is also interested in latest technologies in web development, high availability backends and DevOps.
          </p>
        </div>
      </div>
    </section>

    <section id="experiences">
      <div className="row">
        <div className="listing col-12 col-lg-6">
          <h2><i className="fa fa-briefcase"/>Work Experiences</h2>
          <ul>
            <Experience name="Senior Developer" company="SnugDesign s.r.o." date="2020 - now" text={`
                Analytical work for further development of growing projects, implementation and automation of 
                development processes, collaboration on development of financial platform eDO finance.
            `}/>
            <Experience name="Artificial Intelligence Researcher" company="MLJ solutions a.s" date="2020 - 2022" text={`
                External consulting of data mining projects, designing of Artificial Intelligence solutions and
                discussing achieved results. Cooperation on development of recommendation systems and 
                operation management systems.
            `}/>
            <Experience name="Senior Developer" company="a-netgroup, a.s." date="2013 - 2019" text={`
                Development of e-commerce platform “a-net guru” in PHP language, includes system architecture design, DevOps.
                Collaboration on the project EximTOURS.cz (and other language versions) includes analytical activities,
                implementation of the interlayer in Spring Framework (Java language).
                Code review and leadership of junior programmers.
            `}/>
            <Experience name="Junior Researcher" company="Brno University of Technology" date="2014 - 2020" text={`
                Co-researcher on scientific projects. Latest year as an consultant in the fiels of web development
                and artificial intelligence based text analysis.
            `}/>
            <Experience name="Java Developer" company="Brno University of Technology" date="2013 - 2013" text={`
                Participation on the project optimization processes. The output of this project is a system capable of
                scheduling the work of employees and make decisions better than human.
            `}/>
            <Experience name="Junior PHP Developer" company="WDT, s.r.o." date="2013 - 2013" text={`
                Programming in vanilla PHP and JavaScript (mostly jQuery). Work in a team, developing of CMS magnetpro,
                custommer support.
            `}/>
          </ul>
        </div>
        <div className="listing col-12 col-lg-6 pt-5 pt-lg-0">
          <h2><i className="fa fa-flask"/>Research Experiences</h2>
          <ul>
            <Project id="MPO reg. no. FV20044" date="2017 - 2020" company="3S, s.r.o." text={`
                Expert system for automatic analysis and management of large data repositories of manufacturing companies.
                Keywords: artificial intelligence; big data; data warehouses; predictive analytics.
            `}/>
            <Project id="MVČR reg. no. VI2VS/554" date="2017 - 2019" company="GiTy, a.s." text={`
                Hardware-accelerated identification of people using videos using deep machine learning.
                Keywords: convolutional neural network; data mining; image similarity; noise training.
            `}/>
            <Project id="MVČR reg. no. VI2VS/554" date="2016 - 2016" company="Honeywell International Inc." text={`
                Connected homes - thermostat datamining (economic treaty), reference person Ondřej Lipták -
                Leader of electronic design group at Honeywell, Brno Hardware Center of Excellence.
            `}/>
            <Project id="IGA reg. no. FEKT/FSI-J-16-3564" date="2016 - 2016" company="Institute of Physical Engineering"
                     text={`
                Classification of metallurgical materials by laser-induced plasma spectrometry using deep neural networks.
            `}/>
            <Project id="TAČR reg. no. TH01010503" date="2015 - 2016" company="Honeywell Czech, spol. s r.o." text={`
                Advanced meteorological information for aviation.
                Aim to provide flight crew with actual and precise weather information in a way which brings
                high added value without significant investments into the avionics equipment or ground infrastructure.
            `}/>
            <Project id="MPO reg. no. FR-TI4/151" date="2014 - 2015" company="Webnode CZ, s.r.o." text={`
                Research and development of emotion detection technology in unstructured data.
                The software prototype implementation can help the manufacturing companies to evaluate emotions in text.
                Keywords: data mining; emotion recognition; genetic algorithms; text processing.
            `}/>
          </ul>
        </div>
      </div>
    </section>

    <section className="bg-secondary">
      <div className="row">
        <div className="listing col-12 col-lg-6">
          <h2><i className="fa fa-graduation-cap"/>Education</h2>
          <ul>
            <Education degree="Ph.D." school="Brno University of Technology" date="2018"
                       text="Department of Telecommunications, Faculty of Electrical Engineering and communication."
                       thesis="Doctoral Thesis"
                       thesisName="Text Mining Based on Artificial Intelligence Methods"
                       thesisLink="https://www.vutbr.cz/en/students/final-thesis?zp_id=113382"
            />
            <Education degree="Ing. / MSc." school="Brno University of Technology" date="2014"
                       text="Communications and Informatics on Faculty of Electrical Engineering and communication."
                       thesis="Master's Thesis"
                       thesisName="Job Scheduling in Logistic Warehouses"
                       thesisLink="https://www.vutbr.cz/en/students/final-thesis?zp_id=73841"
            />
            <Education degree="Bc." school="Brno University of Technology" date="2012"
                       text="Teleinformatics on Faculty of Electrical Engineering and communication."
                       thesis="Bachelor's Thesis"
                       thesisName="Electronic Booking System for Laboratories and Laboratory Equipment"
                       thesisLink="https://www.vutbr.cz/en/students/final-thesis?zp_id=52139"
            />
          </ul>
        </div>
        <div className="listing col-12 col-lg-6 pt-5 pt-lg-0">
          <h2><i className="fa fa-thumbs-up"/>Skills</h2>
          <p className="skills">
            <span className="badge badge-primary">Artificial Intelligence</span>
            <span className="badge badge-primary">English B2/C1</span>
            <span className="badge badge-primary">Keras / Tensorflow</span>
            <span className="badge badge-primary">Java</span>
            <span className="badge badge-primary">PHP</span>
            <span className="badge badge-primary">Go</span>
            <span className="badge badge-primary">OOP</span>
            <span className="badge badge-primary">Reactive X</span>
            <span className="badge badge-primary">GitLab</span>
            <span className="badge badge-primary">Git Flow</span>
            <span className="badge badge-primary">Docker / Swarm</span>
            <span className="badge badge-primary">CI / CD</span>
            <span className="badge badge-primary">Nette framework</span>
            <span className="badge badge-primary">Nextras ORM</span>
            <span className="badge badge-primary">Spring Boot</span>
            <span className="badge badge-primary">Spring Cloud</span>
            <br/>
            <span className="badge badge-secondary">Android</span>
            <span className="badge badge-secondary">CakePHP</span>
            <span className="badge badge-secondary">Doctrine ORM</span>
            <span className="badge badge-secondary">ElasticSearch</span>
            <span className="badge badge-secondary">HTML5</span>
            <span className="badge badge-secondary">IPMA Level D</span>
            <span className="badge badge-secondary">MySQL</span>
            <span className="badge badge-secondary">NodeJS</span>
            <span className="badge badge-secondary">Python</span>
            <span className="badge badge-secondary">Redis</span>
            <span className="badge badge-secondary">CSS / Sass / Less</span>
            <span className="badge badge-secondary">Sentry</span>
          </p>
        </div>
      </div>
    </section>

    <section id="publications">
      <div className="row">
        <div className="listing col-12 col-lg-9">
          <h2><i className="fa fa-graduation-cap"/>Publications</h2>
          <ul>
            <li className="font-weight-bold">Povoda, L., Burget, R., Brezany, P.
              Text sentiment analysis for operational management, WILLEY, book chapter (2019)
            </li>
            <li className="font-weight-bold">Povoda, L., Burget, R., Masek, J., Uher, V., & Dutta, M. K. (2016).
              Optimization Methods in Emotion Recognition System. <i>Radioengineering</i>, 25(3), 565-572.
            </li>
            <li className="font-weight-bold">Masek, J., Burget, R., Povoda, L., & Dutta, M. K. (2016). Multi–GPU
              Implementation of Machine Learning Algorithm using CUDA and OpenCL. <i>International Journal of Advances
                in Telecommunications, Electrotechnics, Signals and Systems</i>, 5(2), 101-107.
            </li>
            <li>Kolařík, M., Burget, R., Uher, V., Povoda, L. Superresolution of MRI brain images using unbalanced 3D
              Dense-U-Net network. In <i>2019 42nd International Conference on Telecommunications and Signal
                Processing (TSP)</i>. (pp. 643-646). ISBN: 978-1-7281-1864-2.
            </li>
            <li>Myška, V., Burget, R., Povoda, L., Dutta, M. Linguistically independent sentiment analysis using
              convolutional-recurrent neural networks model. In <i>2019 42nd International Conference on Telecommunications
                and Signal Processing (TSP)</i>. (pp. 212-215). ISBN: 978-1-7281-1864-2.
            </li>
            <li>Rajnoha, M., Burget, R., Povoda, L. Image Background Noise Impact on Convolutional Neural Network Training.
              In <i>2018 10th International Congress on Ultra Modern Telecommunications and Control Systems and Workshops
                (ICUMT)</i>. (pp. 168-171). ISBN: 978-1-5386-9361-2.
            </li>
            <li>Rajnoha, M., Povoda, L., Masek, J., Burget, R., & Dutta, M. K. (2018, February). Pedestrian Detection
              from Low Resolution Public Cameras in the Wild. In <i>2018 5th International Conference on Signal
                Processing and Integrated Networks (SPIN)</i> (pp. 291-295). ISBN: 978-1-5386-3045-7.
            </li>
            <li>Povoda, L., Burget, R., Dutta, M. K., & Sengar, N. (2017, February). Genetic optimization of big data
              sentiment analysis. In <i>2017 4th International Conference on Signal Processing and Integrated Networks
                (SPIN)</i> (pp. 141-144). IEEE.
            </li>
            <li>Mehta, G., Dutta, M. K., Burget, R., & Povoda, L. (2016, June). Biometric data security using Fractional
              Fourier Transform and chaotic theory. In <i>2016 39th International Conference on Telecommunications and
                Signal Processing (TSP)</i> (pp. 533-537). IEEE.
            </li>
            <li>Povoda, L., Burget, R., & Dutta, M. K. (2016, June). Sentiment analysis based on Support Vector Machine
              and Big Data. In <i>2016 39th International Conference on Telecommunications and Signal Processing
                (TSP)</i> (pp. 543-545). IEEE.
            </li>
            <li>Masek, J., Burget, R., Povoda, L., & Harvanek, M. Image Search Using Similarity Measures Based on
              Circular Sectors. In <i>Computer Science & Information Technology (CS & IT)</i>.
            </li>
            <li>Povoda, L., Arora, A., Singh, S., Burget, R., & Dutta, M. K. (2015, October). Emotion recognition from
              helpdesk messages. In <i>2015 7th International Congress on Ultra Modern Telecommunications and Control
                Systems and Workshops (ICUMT)</i> (pp. 310-313). IEEE.
            </li>
            <li>Sengar, N., Dutta, M. K., Burget, R., & Povoda, L. (2015, July). Detection of diabetic macular edema in
              retinal images using a region based method. In <i>2015 38th International Conference on Telecommunications
                and Signal Processing (TSP)</i> (pp. 412-415). IEEE.
            </li>
            <li>Povoda, L., Burget, R., Masek, J., & Dutta, M. K. (2015, February). Job shop scheduling problem with
              heuristic genetic programming operators. In <i>2015 2nd International Conference on Signal Processing and
                Integrated Networks (SPIN)</i> (pp. 702-707). IEEE.
            </li>
            <li>Karasek, J., Burget, R., Povoda, L., Dutta, M. K., & Singh, A. (2014, November). Genetic programming
              operators for work-flow optimization in logistic distribution centers. In <i>2014 International Conference
                on Medical Imaging, m-Health and Emerging Communication Systems (MedCom)</i> (pp. 105-109). IEEE.
            </li>
            <li>Karasek, J., Burget, R., & Povoda, L. (2014). Logistic warehouse process optimization through genetic
              programming algorithm. In <i>Modern Trends and Techniques in Computer Science</i> (pp. 29-40). Springer,
              Cham.
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`

export default IndexPage
