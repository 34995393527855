import React from 'react'
import PropTypes from 'prop-types'

function Project({ id, company, date, text }) {
  return (
    <li>
      <h3><strong>{id},</strong> with {company}</h3>
      <p>{date}</p>
      <p>{text}</p>
    </li>
  )
}

Project.propTypes = {
  id: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Project
